import { getData } from './commonService';

export default class DashboardService {
    #defaultUrl = '/api/Dashboard';
    #defaultResourceName = 'Dashboard';

    async getDashboard(projectId, userId) {
        return await getData(this.#defaultUrl + '/' + projectId +"/" + userId, this.#defaultResourceName);
    }

}
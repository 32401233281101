<template>
    <div class='col-12 md:col-6 lg:col-3'>
        <a :href='url'>
            <div class='card p-0 widget-overview-box widget-overview-box-2'>
                <div class='flex align-items-center justify-content-start pt-3 pb-2 px-4'>
                    <i class='pi pi-shopping-cart text-6xl'></i>
                    <div class='pl-3 overflow-hidden'>
                        <span class='header block white-space-nowrap overflow-hidden'>ÖNCEKİ GÜN SATIŞLAR</span>
                        <span class='block text-4xl font-bold'
                              :style="{'color': ($appState.colorScheme === 'light' ? '#CB6E17' : '#DE911D')}">
                                {{ dashboardData.totalActualSales }}
                            </span>
                    </div>
                </div>
                <img
                    :src="'layout/images/dashboard/locations' + ($appState.colorScheme === 'light' ? '' : '-dark') + '.svg'"
                    class='w-full' alt='users'>
            </div>
        </a>
    </div>
</template>
<script>

import { getUserType } from '../../../pages/common/commonConstantFunctions';

export default {
    name: 'DailySalesDashboardPanel',
    props: {
        dashboardData: {}
    },
    data() {
        return {
            isAdmin:false,
            url: "/#/CalculateFoodPayment"
        }
    },
    mounted() {
        if(getUserType() == 'Admin')
        {
            this.isAdmin = true;
            this.url = "/#/Report/FoodPaymentReport"
        }
    },

}
</script>
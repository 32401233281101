<template>
    <div class='layout-dashboard'>
        <div class='grid'>

            <PersonCountDashboardPanel :dashboard-data='dashboardData'  v-if='isDashboardLoaded'></PersonCountDashboardPanel>

            <DailySalesDashboardPanel :dashboard-data='dashboardData' v-if='isDashboardLoaded'></DailySalesDashboardPanel>

            <FirmDashboardPanel :dashboard-data='dashboardData' v-if='isDashboardLoaded'></FirmDashboardPanel>
            <ProjectDashboardPanel :dashboard-data='dashboardData' v-if='isDashboardLoaded'></ProjectDashboardPanel>

            <MessagesDashboardPanel :dashboard-data='dashboardData' v-if='isDashboardLoaded'></MessagesDashboardPanel>


            <WeeklySaleDashboardPanel v-if='isDashboardLoaded && !isAdmin'
                                      :dashboard-data='dashboardData'></WeeklySaleDashboardPanel>
            <WeeklySaleAdminDashboardPanel v-if='isDashboardLoaded && isAdmin' :dashboard-data='dashboardData'></WeeklySaleAdminDashboardPanel>

            <DayOffDashboardPanel v-if='isDashboardLoaded' :dashboard-data='dashboardData'></DayOffDashboardPanel>
            <ShortcutsDashboardPanel v-if='!isAdmin'></ShortcutsDashboardPanel>
            <ShortcutsAdminDashboardPanel v-if='isAdmin'></ShortcutsAdminDashboardPanel>


        </div>
    </div>
</template>

<script>
import DashboardService from '../services/dashboardService';
import DayOffDashboardPanel from './shared/dashboard/dayOffDashboardPanel';
import ShortcutsDashboardPanel from './shared/dashboard/shortcutsDashboardPanel';
import WeeklySaleDashboardPanel from './shared/dashboard/weeklySaleChartDashboardPanel';
import PersonCountDashboardPanel from './shared/dashboard/personCountDashboardPanel';
import DailySalesDashboardPanel from './shared/dashboard/dailySalesDashboardPanel';
import FirmDashboardPanel from './shared/dashboard/firmDashboardPanel';
import MessagesDashboardPanel from './shared/dashboard/messagesDashboardPanel';
import { getActiveProjectId, getUserId, getUserType } from '../pages/common/commonConstantFunctions';
import WeeklySaleAdminDashboardPanel from './shared/dashboard/weeklySaleChartAdminDashboardPanel';
import ProjectDashboardPanel from './shared/dashboard/projectDashboardPanel';
import ShortcutsAdminDashboardPanel from './shared/dashboard/shortcutsAdminDashboardPanel';

export default {
    components: { ShortcutsAdminDashboardPanel, ProjectDashboardPanel, WeeklySaleAdminDashboardPanel, MessagesDashboardPanel, FirmDashboardPanel, DailySalesDashboardPanel, PersonCountDashboardPanel, WeeklySaleDashboardPanel, ShortcutsDashboardPanel, DayOffDashboardPanel },
    _dashboardService: null,

    data() {
        return {
            projectId: 0,
            isAdmin:false,
            isDashboardLoaded: false,
            dashboardData: {
                totalEmployeeCount: 0,
                firmTotalCount: 0,
                totalActualSales: 0,
                messageCount: 0,
                todayDayOffList: [],
                chartData: {},
                chartExtendedData: {
                    maxActualCount: 0,
                    maxExpectedCount: 0,
                },
                messageCounts: {
                    receivedCount:0,
                    sentCount:0
                }
            },
        };
    },
    created() {
        if(getUserType() != 'Admin')
        {
            this.isAdmin = false;
            this.projectId = getActiveProjectId();
        }
        else {
            this.isAdmin = true;
            this.projectId = -1;
        }

        this._dashboardService = new DashboardService();
    },
    async mounted() {
        let dashboardResponse = await this._dashboardService.getDashboard(this.projectId, getUserId());
        this.dashboardData = dashboardResponse.data;
        this.isDashboardLoaded = true;
    },
    methods: {},
};
</script>

<style scoped>

</style>

<template>
    <div class="col-12 md:col-6 lg:col-3">
        <div class="card p-0 widget-people h-full relative">

            <div class="header font-bold py-4 px-3">İzinli Personel</div>

            <div class="overflow-y-auto" style="max-height: calc(500px - 100px)">
                
                <div v-for='dayOffPerson in dashboardData.todayDayOffList' :key='dayOffPerson.id' class="mb-4 px-3 flex align-items-center justify-content-start">
                    <Avatar :label="dayOffPerson.tag" size="large" shape="circle" class="text-base font-bold" :style="{'background-color':'rgba(255, 155, 155, 0.1)', 'color': '#CF1124', 'border': '1px solid #FF9B9B'}"></Avatar>
                    <div class="ml-3">
                        <span class="name block font-bold">{{dayOffPerson.name + " " + dayOffPerson.surName }}</span>
                        <span class="position block">{{dayOffPerson.department  }}</span>
                        <span v-if='isAdmin' class="position block text-blue-600">{{dayOffPerson.projectName  }}</span>
                    </div>
                </div>
                
                <div class="mb-4 px-3 flex align-items-center justify-content-start" v-if='dashboardData.todayDayOffList.length <= 0'> Bugün izinli personel bulunamadı. </div>
            </div>

            <a :href="url">
                <div class="footer cursor-pointer flex align-items-center justify-content-center p-3 absolute bottom-0 w-full">
                    <span class="font-bold mr-2">HEPSİNİ GÖR</span>
                    <i class="pi pi-chevron-right"></i>
                </div>
            </a>

        </div>
    </div>
</template>

<script>
import { getUserType } from '../../../pages/common/commonConstantFunctions';

export default {
    name: 'DayOffDashboardPanel',
    props: {
        dashboardData: {}
    },
    data() {
        return {
            isAdmin:false,
            url:"/#/ProjectEmployee/DayOffList"
        }
    },
    mounted() {
        if(getUserType() == 'Admin')
        {
            this.isAdmin = true;
            this.url = "/#/Accounting/EmployeeDayOffList"
        }
    },
    methods: {
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }

    }
};
</script>

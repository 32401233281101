<template>
    <div class='col-12 md:col-6 lg:col-3' v-if='isAdmin'>
        <a href='/#/Project/List'>
            <div class='card p-0 widget-overview-box widget-overview-box-3'>
                <div class='flex align-items-center justify-content-start pt-3 pb-2 px-4'>
                    <i class='pi pi-building text-6xl'></i>
                    <div class='pl-3 overflow-hidden'>
                        <span class='header block white-space-nowrap overflow-hidden'>AKTİF PROJELER</span>
                        <span class='block text-4xl font-bold'
                              :style="{'color': ($appState.colorScheme === 'light' ? '#147D64' : '#27AB83')}">
                                {{ dashboardData.totalProjectCount }}
                            </span>
                    </div>
                </div>
                <img
                    :src="'layout/images/dashboard/rate' + ($appState.colorScheme === 'light' ? '' : '-dark') + '.svg'"
                    class='w-full' alt='users'>
            </div>
        </a>
    </div>
</template>

<script>
import { getUserType } from '../../../pages/common/commonConstantFunctions';

export default {
    name: 'ProjectDashboardPanel',
    props: {
        dashboardData: {}
    },
    data() {
        return {
            isAdmin:false,
        }
    },
    mounted() {
        if(getUserType() == 'Admin')
        {
            this.isAdmin = true;
        }
    },

}
</script>
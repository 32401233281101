<template>
    <div class="col-12 md:col-6 xl:col-3">
        <div class="card p-0 widget-list-type2 h-full relative">

            <div class="overflow-y-auto" style="max-height: calc(120% - 120px)">

                <ul class="list-none pl-5 pr-5 pt-4">

                    <li class="flex align-items-center justify-content-between pt-2 pb-3">
                        <div class="flex align-items-center justify-content-start">
                            <img src="/layout/images/icon/aksam_yemegi.svg" alt="verona-layout" height="42" class="border-round"/>
                            <div class="ml-2">
                                <span class="font-bold block">Hakediş Ekle</span>
                                <a href="/#/CalculateFoodPayment">
                                    <span class="subtext text-sm block">Listele</span>
                                </a>
                            </div>
                        </div>
                    </li>

                    <hr>

                    <li class="flex align-items-center justify-content-between pt-2 pb-3">
                        <div class="flex align-items-center justify-content-start">
                            <img src="/layout/images/icon/avans_toplam.svg" height="42" class="border-round"/>
                            <div class="ml-2">
                                <span class="font-bold block">Avans İşlemleri</span>
                                <a href="/#/ProjectEmployee/AllowanceList">
                                    <span class="subtext text-sm block">Listele</span>
                                </a>
                            </div>
                        </div>
                    </li>

                    <hr>

                    <li class="flex align-items-center justify-content-between pt-2 pb-3">
                        <div class="flex align-items-center justify-content-start">
                            <img src="/layout/images/icon/resmi_odeme.svg" height="42" class="border-round"/>
                            <div class="ml-2">
                                <span class="font-bold block">Maaş Hesapla</span>
                                <a href="/#/ProjectEmployee/Calculate">
                                    <span class="subtext text-sm block">Listele</span>
                                </a>
                            </div>
                        </div>
                    </li>

                    <hr>

                    <li class="flex align-items-center justify-content-between pt-2 pb-3">
                        <div class="flex align-items-center justify-content-start">
                            <img src="/layout/images/icon/saat.svg" alt="verona-layout" height="42" class="border-round"/>
                            <div class="ml-2">
                                <span class="font-bold block">Mesai</span>
                                <a href="/#/ProjectEmployee/OvertimeList">
                                    <span class="subtext text-sm block">Listele</span>
                                </a>
                            </div>
                        </div>
                    </li>

                    <hr>

                    <li class="flex align-items-center justify-content-between pt-2 pb-3">
                        <div class="flex align-items-center justify-content-start">
                            <img src="/layout/images/icon/sepet.svg" alt="verona-layout" height="42" class="border-round"/>
                            <div class="ml-2">
                                <span class="font-bold block">Taşeron Taahhüt</span>
                                <a href="/#/Firm/DefaultList">
                                    <span class="subtext text-sm block">Listele</span>
                                </a>
                            </div>
                        </div>
                    </li>




                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { getUserType } from '../../../pages/common/commonConstantFunctions';

export default {
    name: 'ShortcutsDashboardPanel',
    data() {
        return {
            isAdmin:false,
        }
    },
    mounted() {
        if(getUserType() == 'Admin')
        {
            this.isAdmin = true;
        }
    },

}
</script>
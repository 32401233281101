<template>
    <div class='col-12 lg:col-6' v-if='!isAdmin'>
        <div class='card widget-overview-chart h-full'>
            <div class='card-header'>
                <span class='font-bold'>Haftalık Satışlar</span>
            </div>

            <div class='graph mb-3'>
                <Chart ref='overview' type='bar' :data='overviewChart' :options='overviewChartOptions'
                       id='overview-chart'></Chart>
            </div>

            <div class='graph-footer flex align-items-center justify-content-end'>
                <Button type='button' label='Gerçekleşen'
                        class='p-button-outlined p-button-rounded p-button-sm font-bold mr-3 py-1 first-data-button'></Button>
                <Button type='button' label='Taahhüt'
                        class='p-button-outlined p-button-rounded p-button-sm font-bold py-1 second-data-button'></Button>
            </div>
        </div>
    </div>
</template>
<script>

export  default {
    name: "WeeklySaleDashboardPanel",
    watch: {
        '$appState.colorScheme'() {
            this.overviewChartOptions = this.getOrdersOptions();
            this.overviewChart.datasets[1].backgroundColor[0] = this.$appState.colorScheme === 'dark' ? '#879AAF' : '#E4E7EB';
        },
    },
    props: {
        dashboardData: {}
    },
    data() {
        return {
            isAdmin:false,
            overviewChart: {},
            overviewChartOptions: null,
            chartOptions: {
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    y: {
                        max: 100,
                        min: 0,
                        ticks: {
                            color: '#A0A7B5',
                        },
                    },
                    x: {
                        grid: {
                            display: true,
                        },
                        ticks: {
                            color: '#A0A7B5',
                            beginAtZero: true,
                        },
                    },
                },
            },
            chart: null,
            chartMaxCount: 1000,
        }
    },
    mounted() {

        this.chartMaxCount = (this.dashboardData.chartExtendedData.maxActualCount > this.dashboardData.chartExtendedData.maxExpectedCount ? this.dashboardData.chartExtendedData.maxActualCount : this.dashboardData.chartExtendedData.maxExpectedCount) + 100;
        this.overviewChartOptions = this.getOrdersOptions();
        this.overviewChart = this.dashboardData.chartData;
    },
    methods:{
        getOrdersOptions() {
            return {
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                responsive: true,
                hover: {
                    mode: 'index',
                },
                scales: {
                    y: {
                        max: this.chartMaxCount,
                        min: 0,
                        ticks: {
                            stepSize: 0,
                            callback: function(value, index) {
                                if (index === 0) {
                                    return value;
                                } else {
                                    return value;
                                }
                            },
                            color: this.$appState.colorScheme === 'dark' ? '#DBE2EB' : '#3E4C59',
                        },
                        grid: {
                            borderDash: [2, 2],
                            color: this.$appState.colorScheme === 'dark' ? '#4E657F' : '#E4E7EB',
                            drawBorder: false,
                        },
                    },
                    x: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            beginAtZero: true,
                            color: this.$appState.colorScheme === 'dark' ? '#DBE2EB' : '#3E4C59',
                        },
                    },
                },
            };
        },
    }

}
</script>
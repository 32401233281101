<template>
    <div class='col-12 md:col-6 lg:col-3'>
        <a :href='url'>
            <div class='card p-0 widget-overview-box widget-overview-box-1'>
                <div class='flex align-items-center justify-content-start pt-3 pb-2 px-4'>
                    <i class='pi pi-users text-6xl'></i>
                    <div class='pl-3 overflow-hidden'>
                        <span class='header block white-space-nowrap overflow-hidden'>PERSONEL</span>
                        <span class='block text-4xl font-bold'
                              :style="{'color': ($appState.colorScheme === 'light' ? '#035388' : '#1992D4')}">
                                {{ dashboardData.totalEmployeeCount }}
                            </span>
                    </div>
                </div>
                <img
                    :src="'layout/images/dashboard/users' + ($appState.colorScheme === 'light' ? '' : '-dark') + '.svg'"
                    class='w-full' alt='users'>
            </div>
        </a>
    </div>
</template>
<script>

import { getUserType } from '../../../pages/common/commonConstantFunctions';

export default {
    name: 'PersonCountDashboardPanel',
    props: {
        dashboardData: {}
    },
    data() {
        return {
            isAdmin:false,
            url:"/#/ProjectEmployee/List",
        }
    },
    mounted() {
        if(getUserType() == 'Admin')
        {
            this.isAdmin = true;
            this.url = "/#/Accounting/ProjectEmployeeList"
        }
    },

}
</script>
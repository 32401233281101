<template>
    <div class='col-12 md:col-6 lg:col-3' v-if='!isAdmin'>
    <a href='/#/Messages/IncomingList'>
        <div class='card p-0 widget-overview-box widget-overview-box-4 md:h-full md:relative'>
            <div class='flex align-items-center justify-content-start pt-3 pb-2 px-4'>
                <i class='pi pi-comments text-6xl'></i>
                <div class='pl-3 overflow-hidden'>
                    <span class='header block white-space-nowrap overflow-hidden'>MESAJLARIM</span>
                    <span class='block text-4xl font-bold' :style="{'color': ($appState.colorScheme === 'light' ? '#6551B7' : '#A896FF')}">
                         <b class="font-light text-xl">Gelen Talep</b> {{ dashboardData.messageCounts.receivedCount }}  &nbsp;&nbsp;&nbsp; <b class="font-light text-xl">Giden Talep</b> {{ dashboardData.messageCounts.sentCount }}
                    </span>
                   
                </div>
            </div>
            <img :src="'layout/images/dashboard/interactions' + ($appState.colorScheme === 'light' ? '' : '-dark') + '.svg'" class='w-full md:absolute md:bottom-0' alt='users'>
        </div>
    </a>
    </div>
</template>

<script>

export default {
    name: 'MessagesDashboardPanel',
    props: {
        dashboardData: {}
    },
    data() {
        return {
            isAdmin:false,
        }
    },
    mounted() {

    },

}
</script>